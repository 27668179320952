import * as React from 'react';
import styled from 'styled-components';

interface ICloseIcon {
  className?: string;
  onClick?: (e?: React.SyntheticEvent) => void;
}

export const CloseIcon: React.FC<ICloseIcon> = ({ className, onClick }) => {
  return (
    <StyledIcon className={className} onClick={onClick}>
      <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.7 0.3C13.3 -0.1 12.7 -0.1 12.3 0.3L7 5.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L7 8.4L12.3 13.7C12.5 13.9 12.8 14 13 14C13.2 14 13.5 13.9 13.7 13.7C14.1 13.3 14.1 12.7 13.7 12.3L8.4 7L13.7 1.7C14.1 1.3 14.1 0.7 13.7 0.3Z"
          fill="#3E3E3E"
        />
      </svg>
    </StyledIcon>
  );
};

const StyledIcon = styled.div`
  width: 24px;
  height: 24px;
`;
