import * as React from 'react';
import styled from 'styled-components';

import { AdvertisingLogo } from './AdvertisingLogo';
import { Button } from '../button/Button';
import { Modal } from '../modal/Modal';
import { VideoPlayer } from '../video-player/Index';
import { Logo } from '../logo/office/Index';

interface IAdvertisingPageSection extends React.HTMLAttributes<HTMLDivElement> {
  logoUrl?: string;
  videoUrl: string;
  readmoreLink: string;
  logoWithManualLabel: boolean;
  name?: string;
}

interface IAdvertisingSection {
  style?: any;
}

export const AdvertisingPageSection: React.FC<IAdvertisingPageSection> = (
  props
) => {
  const [showVideo, setShowVideo] = React.useState<boolean>(false);

  React.useEffect(() => {
    return () => {
      setShowVideo(false);
    };
  }, []);

  return (
    <AdvertisingSection
      style={
        props?.style
          ? { ...props.style, background: 'transparent' }
          : { marginTop: '5em', background: 'transparent' }
      }
    >
      <div className="container">
        {props.children}
        {props.logoWithManualLabel ? (
          <Logo
            style={{ margin: '0 auto', paddingTop: '50px' }}
            name={props.name}
            alt="bilde av logo"
          />
        ) : (
          <AdvertisingLogo className="logo" src={props.logoUrl} />
        )}

        <ButtonGroupGrid>
          <Modal
            id="portals"
            noBodyScroll={true}
            showModal={showVideo}
            overlay={true}
            showCloseButton={true}
            onClickClose={() => setShowVideo(false)}
            onClickOutside={() => setShowVideo(false)}
          >
            <VideoPlayer type="vimeo" url={props.videoUrl} />
          </Modal>
          <Button
            type="tertiary"
            onClick={(e) => {
              setShowVideo(true);
            }}
          >
            Se video
          </Button>
          <Button type="tertiary" as="a" href={props.readmoreLink}>
            Les mer
          </Button>
        </ButtonGroupGrid>
      </div>
    </AdvertisingSection>
  );
};

const AdvertisingSection = styled.section<IAdvertisingSection>`
  width: 100%;
  padding: 70px 0;
  background: ${({ theme }) => theme.colors.burgundy};

  .container {
    display: flex;
    flex-flow: column;
    padding: 0 30px;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;

    @media all and (max-width: 1000px) {
      padding: 0 20px;
    }
  }

  .logo {
    margin-bottom: 0;
  }
`;

const ButtonGroupGrid = styled.div`
  display: grid;
  grid-template-columns: 65px 60px;
  grid-column-gap: 20px;
  width: 100%;
  justify-content: center;

  button,
  a {
    display: inline-block;
    border-color: ${({ theme }) => theme.colors.coral};
  }
`;
