import * as React from 'react';
import styled, { css } from 'styled-components';
import { animated, useSpring } from 'react-spring';

import { useProgressiveImage } from '../slideshow/hooks/useProgressiveImage';
import { darken } from 'polished';

interface IHero {
  style?: any;
  imageUrl?: string;
  opacity: number;
  position?: string;
  size?: string;
  height?: string;
  className?: string;
  alt?: string;
}

export const Hero: React.FC<IHero> = ({
  style,
  className,
  opacity,
  position,
  size,
  imageUrl,
  height,
  alt,
  children
}) => {
  const { loaded } = useProgressiveImage({
    url: imageUrl
  });
  const imageLoadedProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: loaded ? (opacity ? opacity : 1) : 0 }
  });

  return (
    <StyledHero
      style={height ? { height, ...style } : { ...style }}
      className={className}
    >
      {/* className={className ? className : 'hero-content'} */}
      <div className="hero-content">{children}</div>
      <StyledHeroBackground
        position={position}
        size={size}
        style={imageLoadedProps}
        src={imageUrl}
        alt={alt}
      />
    </StyledHero>
  );
};

const StyledHero = styled(animated.div)`
  position: relative;
  width: 100%;
  background: ${({ theme }) => darken(0.05, theme.colors.body)};

  &.top-header-margin {
    margin-top: 66px;
  }
  .hero-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;

    &.flex-bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 300px; /* Sett ønsket høyde for hovedcontaineren */
    }
  }
  &.content-height-1000 {
    height: auto;
    min-height: 900px;
  }

  &.content-height {
    height: auto;
    min-height: 500px;

    &.padding-top-50 {
      padding-top: 50px;
    }

    &.padding-bottom-80 {
      padding-bottom: 80px;
    }

    &.padding-top-150 {
      padding-top: 150px;
    }

    .hero-content {
      height: auto;
    }
    &.background-licorice {
      background-color: ${({ theme }) => theme.colors.licorice};
    }
  }

  &.flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const BaseHeroContent = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 30px;
`;

export const HeroContent = styled(animated.div)`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  max-width: 960px;
  padding: 0 30px;

  h1 {
    margin-bottom: 0;
    width: 100%;
    word-break: break-word;
  }

  h2 {
    width: 100%;
    word-break: break-word;
  }
`;

const StyledHeroBackground = styled(animated.img)<{
  image?: string;
  size?: string;
  position?: string;
}>`
  position: absolute;
  object-fit: cover;
  color: white;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${(props) => props.image}) no-repeat;
  /*   ${({ theme }) => theme.colors.licorice} */
  background-size: ${(props) => (props.size ? props.size : 'cover')};
  background-position: ${(props) =>
    props.position ? props.position : 'center'};
`;
