import * as React from 'react';
import styled from 'styled-components';

type VideoType = 'vimeo' | 'youtube';

interface IVideoPlayer {
  type: VideoType;
  url: string;
}

export const VideoPlayer: React.FC<IVideoPlayer> = ({ type, url }) => {
  return (
    <VideoContainer>
      <VideoContainerInner>
        <div id="tull">
          <iframe
            src={url}
            frameBorder="0"
            allow="fullscreen"
            allowFullScreen
          ></iframe>
        </div>
      </VideoContainerInner>
    </VideoContainer>
  );
};

const VideoContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
`;

export const Color = styled.div`
  background-color: red;
`;

const VideoContainerInner = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;

  iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 0px;
    border: none;
    margin: 0px;
  }
`;
