import * as React from 'react';
import styled from 'styled-components';
import { Logo } from '../logo/office/Index';

export const AdvertisingLogo: React.FC<{ className?: string; src: string }> = (
  props
) => {
  return (
    <StyledAdvertisingLogoWrapper className={props.className}>
      <StyledAdvertisingLogo alt="bilde av logo" src={props.src} />
    </StyledAdvertisingLogoWrapper>
  );
};

const StyledAdvertisingLogoWrapper = styled.div`
  display: grid;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
`;

const StyledAdvertisingLogo = styled.img`
  position: relative;
  width: 100%;
  max-width: 280px;
  margin: 0 auto 10px auto;
`;
