import * as React from 'react';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import * as ReactDOM from 'react-dom';
import { CloseIcon } from '../icons/Close';

interface IModal {
  id: string;
  showModal: boolean;
  showCloseButton?: boolean;
  onClickClose?: (e: any) => void;
  onClickOutside?: (e: any) => void;
  noBodyScroll?: boolean;
  inline?: boolean;
  overlay?: boolean;
  children: any;
}

export const Modal = (props: IModal) => {
  let el: any = null;
  const [portalReady, setReady] = useState(false);
  if (typeof window !== 'undefined') {
    useEffect(() => {
      el = document.createElement('div');
      el.id = 'portal';
      document.getElementById(props.id).appendChild(el);
      setReady(true);
      return () => {
        document.getElementById(props.id).removeChild(el);
      };
    }, []);

    useEffect(() => {
      if (typeof window !== 'undefined' && props?.noBodyScroll) {
        const body = document.getElementsByTagName('body')[0];
        if (props?.showModal) {
          document.body.style.overflowY = 'hidden';
        } else {
          document.body.style.overflowY = '';
        }
      }
    }, [props?.showModal]);

    const ModalComponent = () => {
      if (!props?.inline) {
        return (
          <ModalWrapper>
            <ModalContent>{props.children} </ModalContent>
            {props?.overlay ? (
              <ModalOverlay
                onClick={(e) => {
                  e.stopPropagation();
                  if (typeof props.onClickOutside === 'function') {
                    props.onClickOutside();
                  }
                }}
              />
            ) : null}
            {props.showCloseButton ? (
              <CloseIcon className="modal-close" onClick={props.onClickClose} />
            ) : null}
          </ModalWrapper>
        );
      } else {
        return <>{props.children}</>;
      }
    };

    return portalReady && props.showModal
      ? ReactDOM.createPortal(
          ModalComponent(),
          document.getElementById('portal')
        )
      : null;
  } else {
    return null;
  }
};

const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .modal-close {
    position: fixed;
    top: 25px;
    right: 25px;
    z-index: 10000;

    &:hover {
      cursor: pointer;
    }

    svg {
      path {
        fill: white;
      }
    }
  }
`;

const ModalContent = styled.div`
  position: fixed;
  width: 100%;
  max-width: 960px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 30px;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  @media all and (max-width: 960px) {
    padding: 30px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.85);
  z-index: 9999;
`;
