import * as React from 'react';
import styled from 'styled-components';
import { LogoVertical } from '../LogoVertical';

interface ILogo {
  name?: string;
  style?: any;
  className?: string;
  src?: string;
  alt: string;
  extLogo?: boolean;
}

export const Logo: React.FC<ILogo> = ({
  name,
  style,
  src,
  alt,
  extLogo,
  className
}) => {
  return (
    <StyledOfficeLogo className={className} style={style}>
      {extLogo ? (
        <img src={src} width="100%" alt={alt} style={{ color: 'white' }} />
      ) : (
        <img
          className={className}
          src="https://cdn.reeltime.no/pm_assets/logo/pm_versjon2.svg"
          width="100%"
          alt={alt}
          style={{ color: 'white' }}
        />
      )}

      {name ? (
        <svg
          className={className ? className : 'office-name'}
          viewBox="0 0 1001 300"
          height="30px"
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
          textAnchor="middle"
        >
          <linearGradient id="grad1" x1="10%" y1="30%" x2="80%" y2="80%">
            <stop
              offset="0%"
              stopColor="rgb(193, 126, 84)"
              stopOpacity="1"
            ></stop>
            <stop
              offset="50%"
              stopColor="rgb(241,192,135)"
              stopOpacity="1"
            ></stop>
            <stop
              offset="100%"
              stopColor="rgb(185,113,73)"
              stopOpacity="1"
            ></stop>
          </linearGradient>
          <text x="50%" y="50%" fill="url(#grad1)" dominantBaseline="middle">
            {name}
          </text>
        </svg>
      ) : null}
    </StyledOfficeLogo>
  );
};

const StyledOfficeLogo = styled.div`
  max-width: 280px;
  width: 100%;
  overflow: hidden;
  /*   margin: 0 auto; */

  .office-name {
    position: relative;
    top: 7px;

    text {
      text-transform: uppercase;
      font-size: 150px;
    }
  }

  @media all and (max-width: 800px) {
    max-width: 200px;

    .office-name {
      top: 7px;
      height: 25px;
    }
  }
`;
